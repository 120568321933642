const affirmations = [
  "Grind it out.",
  "By hook or by crook.",
  "This is a means to an end.",
  "Programming isn't about what you know. It's about what you can figure out.",
  "We are what we believe we are.",
  "Remember who hurt you and prove them wrong.",
  "Be greater.",
  "Be your own hero.",
  "It's never too late to be the person you want to be. You need to choose the life you want.",
  "Rise up and conquer, or wither here and die.",
  "Nobody in life gets exactly what they thought they were going to get. But if you work really hard and you're kind, amazing things will happen.",
  "You can't stop the waves, but you can learn how to surf.",
  "No one will ever love you for who you are.",
  "That's why they're called dreams. Because they're not real.",
  "Depression is holding on, and sadness is letting go.",
  "What are your inner demons?",
  "Are you the favorite person of anybody?",
  "The music don't feel like it did when I felt it with you.",
  "Are you watching closely?",
  "Control is an illusion.",
  "Time is a construct.",
  "Dear God, make me a bird, so I can fly far, far away from here.",
  "A thing is a thing, not what is said of that thing.",
  "You have the power to be your best.",
  "Luck is when preparation meets opportunity.",
  "You may delay, but time will not.",
  "It is never too late to be what you might have been.",
  "Adventure may hurt you, but monotony will kill you.",
  "Either I will find a way, or I will make one.",
  "Your life does not get better by chance. It gets better by change.",
  "Time's arrow neither stands still nor reverses. It merely marches forward.",
  "Do it for you. No one else.",
  "Birth is a curse, and existence is a prison.",
  "Every existing thing is born without reason, prolongs itself out of weakness, and dies by chance.",
  "Tomorrow the sun will rise.",
];

export default affirmations;
